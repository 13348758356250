import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

//i18n
import { withTranslation } from 'react-i18next';

class NonAuth extends Component {
    constructor(props) {
        super(props);
        this.state={};
        this.capitalizeFirstLetter.bind(this);
    }
    
    capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2);
    };

    componentDidMount(){
        let currentage = this.capitalizeFirstLetter(this.props.location.pathname);
        const { t } = this.props;

        document.title = currentage + " | " + t('App Name');
    }
    render() {
        return <React.Fragment>
            {this.props.children}
        </React.Fragment>;
    }
}

export default (withRouter((withTranslation()(NonAuth))));