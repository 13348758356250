import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { getLoggedInUser } from '../../helpers/authUtils';

//Import Components
import LeftSidebarMenu from "./LeftSidebarMenu";
import LeftSidebarMenuW2ZAdm from "./LeftSidebarMenuW2ZAdm";
import LeftSidebarMenuAdm from "./LeftSideBarMenuAdm";

//i18n
import { withTranslation } from 'react-i18next';

import {
    ROLE_SUPER_ADMIN,
    ROLE_ADMIN,
    ROLE_RESELLER
} from '../../constants/constants';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state={};
        this.capitalizeFirstLetter.bind(this);
    }

    //function for capital first letter of current page pathname
    capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2);
    };

    componentDidMount() {
        let currentage = this.capitalizeFirstLetter(this.props.location.pathname);
        const { t } = this.props;

        //set document title according to page path name
        document.title = currentage + " | " + t('App Name');
    }
    
    render() {
        const user = getLoggedInUser();
        return (
            <React.Fragment>
                <div className="layout-wrapper d-lg-flex">
                    {/* left sidebar menu */}
                    {(function() {
                        switch (user.data.roles[0].name.toString()) {
                            case ROLE_SUPER_ADMIN:
                                return <LeftSidebarMenuW2ZAdm loggedUser={user} />;
                            case ROLE_ADMIN:
                                return <LeftSidebarMenuAdm loggedUser={user} />;
                            case ROLE_RESELLER:
                                return <LeftSidebarMenu loggedUser={user} />;
                            default:
                                return <LeftSidebarMenu loggedUser={user} />;
                        }
                    })()}

                    {/* render page content */}
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }
}


export default (withRouter((withTranslation()(Index))));