import axios from 'axios';
import { tokenExpired } from '../redux/auth/actions';
import { store } from '../redux';
import errorHandler from '../helpers/errorHandler';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  //baseURL: "http://localhost:8080/wzap",
});

const isAuthTry = (url, method) => {
  return url.indexOf('api/auth') && method === 'post' ? true : false;
};

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { dispatch } = store;
    const { config } = error;
    const status = error.response ? error.response.status : null;
    const { url, method } = config;

    if (status === 401 && !isAuthTry(url, method)) {
      dispatch(tokenExpired());
      return new Promise();
    }

    let message = errorHandler(error);
    return Promise.reject(message);
  }
);

export default api;
