import { createStore, compose, applyMiddleware } from 'redux';

// eslint-disable-next-line import/no-anonymous-default-export
export default (reducers, middlewares) => {
  /*const enhancer =
    process.env.NODE_ENV === 'development'
      ? compose(console.tron.createEnhancer(), applyMiddleware(...middlewares))
      : applyMiddleware(...middlewares);*/


  const enhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;   
  return createStore(reducers, enhancer(applyMiddleware(...middlewares)));
};
