import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import { setActiveTab } from "../../redux/actions";
import logo from "../../assets/images/logo.svg";
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
//import usFlag from "../../assets/images/flags/us.jpg";
//import brazil from "../../assets/images/flags/brazil.jpg";
import avatar1 from "../../assets/images/users/avatar-1.jpg";

function LeftSidebarMenuAdm(props) {

    const profileImage = props.loggedUser.data.image !== null ? props.loggedUser.data.image : avatar1;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    //const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [lng, setlng] = useState("Portuguese");

    /* intilize t variable for multi language implementation */
    const { t } = useTranslation();

    const toggle = () => setDropdownOpen(!dropdownOpen);
    //const toggle2 = () => setDropdownOpen2(!dropdownOpen2);
    const toggleMobile = () => setDropdownOpenMobile(!dropdownOpenMobile);

    const toggleTab = tab => {
        props.setActiveTab(tab);
    }

    const activeTab = props.activeTab;

    /* changes language according to clicked language menu item */
    // eslint-disable-next-line no-unused-vars
    const changeLanguageAction = (lng) => {

        /* set the selected language to i18n */
        i18n.changeLanguage(lng);

        if (lng === "pt")
            setlng("Portuguese");
        else if (lng === "sp")
            setlng("Spanish");
        else if (lng === "gr")
            setlng("German");
        else if (lng === "rs")
            setlng("Russian");
        else if (lng === "it")
            setlng("Italian");
        else if (lng === "eng")
            setlng("English");
    }

    useEffect(() => {
        props.setActiveTab('panel');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <div className="side-menu flex-lg-column me-lg-1">
                <div className="flex-lg-column d-none d-lg-block">
                    <Nav vertical className="side-menu-nav justify-content-center">
                        <Dropdown
                            nav
                            direction="up"
                            isOpen={dropdownOpen}
                            className="btn-group dropup nav-item profile-user-dropdown d-inline-block"
                            toggle={toggle}>
                            <DropdownToggle nav><img src={profileImage} alt="" className="profile-user rounded-circle" /></DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => { toggleTab('profile'); }}>{t('Profile')} <i className="ri-profile-line float-end text-muted"></i></DropdownItem>
                                <DropdownItem onClick={() => { toggleTab('settings'); }}>{t('Setting')} <i className="ri-settings-3-line float-end text-muted"></i></DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem href="/logout">{t('Logout')} <i className="ri-logout-circle-r-line float-end text-muted"></i></DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                </div>

                {/* Start side-menu nav */}
                <div className="flex-lg-column">
                    <Nav pills className="side-menu-nav justify-content-center" role="tablist">
                        <NavItem id="panel">
                            <NavLink id="pills-panel-tab" className={classnames({ active: activeTab === 'panel' })} onClick={() => { toggleTab('panel'); }}>
                                <i className="ri-user-2-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="panel" placement="top">
                            {t('Panel')}
                        </UncontrolledTooltip>
                        <NavItem id="profile">
                            <NavLink id="pills-profile-tab" className={classnames({ active: activeTab === 'profile' })} onClick={() => { toggleTab('profile'); }}>
                                <i className="ri-profile-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="profile" placement="top">
                            {t('Profile')}
                        </UncontrolledTooltip>
                        <NavItem id="operator">
                            <NavLink id="pills-operator-tab" className={classnames({ active: activeTab === 'operator' })} onClick={() => { toggleTab('operator'); }}>
                                <i className="ri-customer-service-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="operator" placement="top">
                            {t('Operators')}
                        </UncontrolledTooltip>
                        <NavItem id="department">
                            <NavLink id="pills-department-tab" className={classnames({ active: activeTab === 'department' })} onClick={() => { toggleTab('department'); }}>
                                <i className="ri-building-3-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="department" placement="top">
                            {t('Departments')}
                        </UncontrolledTooltip>
                        <NavItem id="template">
                            <NavLink id="pills-template-tab" className={classnames({ active: activeTab === 'template' })} onClick={() => { toggleTab('template'); }}>
                                <i className="ri-t-box-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="template" placement="top">
                            {t('Templates')}
                        </UncontrolledTooltip>
                        <NavItem id="Settings">
                            <NavLink id="pills-setting-tab" className={classnames({ active: activeTab === 'settings' })} onClick={() => { toggleTab('settings'); }}>
                                <i className="ri-settings-2-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Settings" placement="top">
                            {t('Settings')}
                        </UncontrolledTooltip>
                        <Dropdown nav direction="up" isOpen={dropdownOpenMobile} toggle={toggleMobile} className="profile-user-dropdown d-inline-block d-lg-none">
                            <DropdownToggle nav><img src={profileImage} alt="menu_adm_avatar" className="profile-user rounded-circle" /></DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => { toggleTab('profile'); }}>{t('Profile')} <i className="ri-profile-line float-end text-muted"></i></DropdownItem>
                                <DropdownItem onClick={() => { toggleTab('settings'); }}>{t('Setting')} <i className="ri-settings-3-line float-end text-muted"></i></DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem href="/logout">{t('Logout')} <i className="ri-logout-circle-r-line float-end text-muted"></i></DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                </div>
                {/* end side-menu nav */}

                {/*<div className="flex-lg-column d-none d-lg-block">
                    <Nav className="side-menu-nav justify-content-center">
                        <Dropdown id="language-drop" nav direction="up" isOpen={dropdownOpen2} className="btn-group dropup profile-user-dropdown" toggle={toggle2}>
                            <DropdownToggle nav>
                                <i className="ri-global-line"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem onClick={() => changeLanguageAction('pt')} active={lng === "Portuguese"}>
                                    <img src={brazil} alt="user" className="me-1" height="12" /> <span className="align-middle">{t('Portuguese')}</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => changeLanguageAction('eng')} active={lng === "English"}>
                                    <img src={usFlag} alt="user" className="me-1" height="12" /> <span className="align-middle">{t('English')}</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <UncontrolledTooltip target="language-drop" placement="top">
                            {t('Idiom')}
                        </UncontrolledTooltip>
                        <NavItem>
                            <NavLink id="light-dark" target="_blank" href="//chatvia-light.react.themesbrand.com/">
                                <i className="ri-sun-line theme-mode-icon"></i>
                            </NavLink>
                            <UncontrolledTooltip target="light-dark" placement="right">
                                {t('Dark / Light Mode')}
                            </UncontrolledTooltip>
                        </NavItem>
                    </Nav>
                </div>*/}
                {/* LOGO */}
                <div className="navbar-brand-box">
                    <Link to="/" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src={logo} alt="logo" height="30" />
                        </span>
                    </Link>

                    <Link to="/" className="logo logo-light">
                        <span className="logo-sm">
                            <img src={logo} alt="logo" height="30" />
                        </span>
                    </Link>
                </div>
                {/* end navbar-brand-box  */}

            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    return {
        ...state.Layout
    };
};

export default connect(mapStatetoProps, { setActiveTab })(LeftSidebarMenuAdm);