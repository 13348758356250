import { call, fork, put, all, takeEvery } from 'redux-saga/effects';
import qs from 'qs';
import api from '../../services/api';
import { updateProfile, loginUserSuccess, registerUserSuccess, forgetPasswordSuccess, signOutSuccess, apiError } from '../actions';

import {
  LOGIN_USER,
  TOKEN_EXPIRED,
  LOGOUT_USER,
  REHYDRATE,
  REGISTER_USER,
  FORGET_PASSWORD
} from './constants';

import {
  ROLE_SUPER_ADMIN,
  ROLE_ADMIN,
  ROLE_RESELLER,
  ROLE_OPERATOR,
  ROLE_OPERATOR_FULL
} from '../../constants/constants';

function* signIn({ payload: { username, password, history } }) {
  try {
    var data = qs.stringify({
      grant_type: 'password',
      username: username,
      password: password,
    });

    var client_id = 'client';
    var client_secret = '123';
    var basicAuth = 'Basic ' + btoa(client_id + ':' + client_secret);

    const response = yield call(api.post, '/oauth/token', data, { 
      headers: { Authorization: basicAuth,  }});

    const { access_token } = response.data;

    api.defaults.headers.Authorization = `Bearer ${access_token}`;
    yield put(loginUserSuccess(access_token));

    const user = yield call(api.get, '/users/find');
    localStorage.setItem('authUser', JSON.stringify(user));

    const onlineUserDTO = { id: user.data.id, online: true };
    yield call(api.put, '/users/updateOnline', onlineUserDTO);

    yield put(updateProfile(user.data));
    
    let instanceId = null;
    const role = user.data.roles[0].name.toString();
    if (role === ROLE_OPERATOR || role === ROLE_OPERATOR_FULL) {
      instanceId = user.data.instance;
    }
    localStorage.setItem('instanceId', JSON.stringify(instanceId));

    switch (user.data.roles[0].name.toString()) {
      case ROLE_SUPER_ADMIN:
        history.push('/wzap-administrator');
        break;
      case ROLE_ADMIN:
        history.push('/administrator');
        break;
      case ROLE_RESELLER:
        history.push('/dashboard');
        break;
      default:
        history.push('/dashboard');
        break;
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logout({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    yield call(() => {
      history.push("/login");
    });
  } catch (err) {}

  yield put(signOutSuccess());
  history.push('/');
}

function* tokenExpired({ payload: { history } }) {
  try {
    yield call(() => {
      history.push("/login");
  });
  } catch {}

  history.push('/');
  //message.success('Sua sessão expirou! Faça o login novamente.');
  console.log('Sua sessão expirou! Faça o login novamente.');
}

/**
 * Register the user
 */
 function* register({ payload: { user } }) {
  try {
      const response = yield call(api.post, '/register', user);
      yield put(registerUserSuccess(response));
  } catch (error) {
      yield put(apiError(error));
  }
}

/**
 * forget password
 */
 function* forgetPassword({ payload: { email } }) {
  try {
      const response = yield call(api.post, '/forget-pwd', { email });
      yield put(forgetPasswordSuccess(response));
  } catch (error) {
      yield put(apiError(error));
  }
}

function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, signIn);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchTokenExpired() {
  yield takeEvery(TOKEN_EXPIRED, tokenExpired);
};

export function* watchSetToken() {
  yield takeEvery(REHYDRATE, setToken);
};

function* authSaga() {
  yield all([
      fork(watchLoginUser),
      fork(watchLogoutUser),
      fork(watchRegisterUser),
      fork(watchForgetPassword),
      fork(watchTokenExpired),
      fork(watchSetToken),
  ]);
}

export default authSaga;