import { combineReducers } from 'redux';

import Adm from './adm/reducers';
import auth from './auth/reducers';
import Chat from './chat/reducers';
import ChatInternal from './chat-internal/reducers';
import Layout from './layout/reducer';
import user from './user/reducer';

export default combineReducers({
    Adm,
    auth,
    Chat,
    ChatInternal,
    Layout,
    user
});