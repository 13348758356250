import {
    CHAT_USER, ACTIVE_CHAT_POSITION, FULL_DIALOG, ADD_LOGGED_USER, CREATE_GROUP, SENDER_NAME, DEPARTMENT_FILTER, TEXT_MESSAGE, MUTED,
    ONLINE_USERS
} from './constants';

//Import Images
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar7 from "../../assets/images/users/avatar-7.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";

const INIT_STATE = {
	active_chat_position : 0,
    muted: false,
    textMessage: "",
    senderName : "",
    departmentFilter : null,
    dialogs: [],
    onlineUsers:[],
    loadTags: false,

    groups : [
        { gourpId : 1, name : "#General", chatImage : "null", isGroup : true, unRead : 0, desc : "General Group",
            members : [
                { userId : 1, name : "Sara Muller", chatImage : "null", role : null },
                { userId : 2, name : "Ossie Wilson", chatImage : avatar8, role : "admin" },
                { userId : 3, name : "Jonathan Miller", chatImage : "null", role : null },
                { userId : 4, name : "Paul Haynes", chatImage : avatar7, role : null },
                { userId : 5, name : "Yana sha", chatImage : avatar3, role : null },
                { userId : 6, name : "Steve Walker", chatImage : avatar6, role : null },
            ]    
        },
        { gourpId : 2, name : "#Reporting", chatImage : "null", isGroup : true, unRead : 23,  desc : "reporing Group here...",
            members : [
                { userId : 1, name : "Sara Muller", chatImage : "null", role : null },
                { userId : 2, name : "Ossie Wilson", chatImage : avatar8, role : "admin" },
                { userId : 3, name : "Jonathan Miller", chatImage : "null", role : null },
                { userId : 4, name : "Paul Haynes", chatImage : avatar7, role : null },
                { userId : 5, name : "Yana sha", chatImage : avatar3, role : null },
                { userId : 6, name : "Steve Walker", chatImage : avatar6, role : null },
            ]    
        },
        { gourpId : 3, name : "#Designer", chatImage : "null", isGroup : true, unRead : 0, isNew : true, desc : "designers Group",
            members : [
                { userId : 1, name : "Sara Muller", chatImage : "null", role : null },
                { userId : 2, name : "Ossie Wilson", chatImage : avatar8, role : "admin" },
                { userId : 3, name : "Jonathan Miller", chatImage : "null", role : null },
                { userId : 4, name : "Paul Haynes", chatImage : avatar7, role : null },
                { userId : 5, name : "Yana sha", chatImage : avatar3, role : null },
                { userId : 6, name : "Steve Walker", chatImage : avatar6, role : null },
            ]    
        },
        { gourpId : 4, name : "#Developers", chatImage : "null", isGroup : true, unRead : 0,  desc : "developers Group",
            members : [
                { userId : 1, name : "Sara Muller", chatImage : "null", role : null },
                { userId : 2, name : "Ossie Wilson", chatImage : avatar8, role : "admin" },
                { userId : 3, name : "Jonathan Miller", chatImage : "null", role : null },
                { userId : 4, name : "Paul Haynes", chatImage : avatar7, role : null },
                { userId : 5, name : "Yana sha", chatImage : avatar3, role : null },
                { userId : 6, name : "Steve Walker", chatImage : avatar6, role : null },
            ]    
        },
        { gourpId : 5, name : "#Project-aplha", chatImage : "null", isGroup : true, unRead : 0, isNew : true, desc : "project related Group",
            members : [
                { userId : 1, name : "Sara Muller", chatImage : "null", role : null },
                { userId : 2, name : "Ossie Wilson", chatImage : avatar8, role : "admin" },
                { userId : 3, name : "Jonathan Miller", chatImage : "null", role : null },
                { userId : 4, name : "Paul Haynes", chatImage : avatar7, role : null },
                { userId : 5, name : "Yana sha", chatImage : avatar3, role : null },
                { userId : 6, name : "Steve Walker", chatImage : avatar6, role : null },
            ]    
        },
        { gourpId : 6, name : "#Snacks", chatImage : "null", isGroup : true, unRead : 0,  desc : "snacks Group",
            members : [
                { userId : 1, name : "Sara Muller", chatImage : "null", role : null },
                { userId : 2, name : "Ossie Wilson", chatImage : avatar8, role : "admin" },
                { userId : 3, name : "Jonathan Miller", chatImage : "null", role : null },
                { userId : 4, name : "Paul Haynes", chatImage : avatar7, role : null },
                { userId : 5, name : "Yana sha", chatImage : avatar3, role : null },
                { userId : 6, name : "Steve Walker", chatImage : avatar6, role : null },
            ]    
        },
    ]
};

const Chat = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHAT_USER:
            return { ...state };

        case ACTIVE_CHAT_POSITION:
            return { 
            	...state,
                active_chat_position: action.payload
            };
        case TEXT_MESSAGE:
            return {
                ...state,
                textMessage: action.payload
            };
        case SENDER_NAME:
            return { 
                ...state,
                senderName : action.payload
            };
        case DEPARTMENT_FILTER:
            return {
                ...state,
                departmentFilter: action.payload
            }
        case MUTED:
            return {
                ...state,
                muted: action.payload
            }
        case FULL_DIALOG:
            return { 
            	...state,
                dialogs : action.payload
            };
        case ONLINE_USERS:
            return { 
                ...state,
                onlineUsers : action.payload
            };
        case ADD_LOGGED_USER:
            const newUser =  action.payload
            return{
                ...state, users : [
                    ...state.users, newUser
                ]
            };
        case CREATE_GROUP:
            const newGroup =  action.payload
            return {
                ...state, groups : [
                    ...state.groups, newGroup
                ]
            };  
    default: return { ...state };
    }
}

export default Chat;