import { 
    LOGIN_USER, 
    LOGIN_USER_SUCCESS,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    TOKEN_EXPIRED, 
    LOGOUT_USER, 
    API_FAILED, 
    FORGET_PASSWORD, 
    FORGET_PASSWORD_SUCCESS 
} from './constants';

export function loginUser(username, password, history) {
    return {
      type: LOGIN_USER,
      payload: { username, password, history },
    };
  }
  
export function loginUserSuccess(token) {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: { token },
  };
}
  
export const registerUser = (user) => ({
  type: REGISTER_USER,
  payload: { user }
});

export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user
});

export function logoutUser(history) {
  return {
    type: LOGOUT_USER,
    payload: { history }
  };
}

export function forgetPassword(email) {
  return {
    type: FORGET_PASSWORD,
    payload: { email }
  };
};

export function forgetPasswordSuccess(passwordResetStatus) {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus
  };
};

export function apiError(error) {
  return {
    type: API_FAILED,
    payload: error
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signOutSuccess() {
  return {
    type: '@auth/SIGN_OUT_SUCCESS',
  };
}
  
export function tokenExpired(history) {
  return {
    type: TOKEN_EXPIRED,
    payload: { history }
  };
}
  
export function rememberCredentials(username, password, checked) {
  return {
    type: '@auth/REMEMBER_CREDENTIALS',
    payload: { username, password, checked },
  };
}