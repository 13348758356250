const INIT_STATE = {
	active_client : 654,
    userStatus: ["ACTIVE", "INACTIVE"],
    clientStatus : [
        {id: 1, name: "TEST"},
        {id: 2, name: "SUBSCRIBER"},
        {id: 3, name: "NON_SUBSCRIBER"},
        {id: 4, name: "BLOCKED"},
        {id: 5, name: "PAYMENT_FAILED"}],
    states: ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MG", "MS", "MT", "PA", "PB", "PE", "PI", "PR", "RJ", "RN", "RO", "RR", "RS", "SC", "SE", "SP", "TO"],
};

const Adm = (state = INIT_STATE, action) => {
     return { ...state };
}

export default Adm;