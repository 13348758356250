import produce from 'immer';
import { 
    LOGIN_USER, 
    LOGIN_USER_SUCCESS, 
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    API_FAILED,
    TOKEN_EXPIRED
} from './constants';

const INITIAL_STATE = {
  user: null,
  token: null,
  signed: false,
  loading: false,
  rememberUsername: null,
  rememberPassword: null,
  checked: false,
  passwordResetStatus: null,
  error: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case LOGIN_USER: {
        draft.loading = true;
        break;
      }
      case LOGIN_USER_SUCCESS: {
        draft.token = action.payload.token;
        draft.signed = true;
        draft.loading = false;
        draft.error = null;
        draft.user = action.payload;
        break;
      }
      case REGISTER_USER: {
        draft.loading = true;
        break;
      }
      case REGISTER_USER_SUCCESS: {
        draft.user = action.payload;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case LOGOUT_USER: {
        draft.user = null;
        break;
      }
      case FORGET_PASSWORD: {
        draft.loading = true;
        break;
      }
      case FORGET_PASSWORD_SUCCESS: {
        draft.passwordResetStatus = action.payload.passwordResetStatus;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case API_FAILED: {
        draft.loading = false;
        draft.error = action.payload;
        break;
      }
      case '@auth/SIGN_FAILURE': {
        draft.loading = false;
        break;
      }
      case TOKEN_EXPIRED:
      case '@auth/SIGN_OUT_SUCCESS': {
        draft.token = null;
        draft.signed = false;
        break;
      }
      case '@auth/REMEMBER_CREDENTIALS': {
        draft.rememberUsername = action.payload.username;
        draft.rememberPassword = action.payload.password;
        draft.checked = action.payload.checked;
        break;
      }
      default:
    }
  });
};