import { ACTIVE_INTERNAL_CHAT_POSITION, TEXT_MESSAGE_INTERNAL, INTERNAL_MUTED, ROLE_DESCRIPTION, FULL_DIALOG_INTERNAL } from './constants';

export const activeInternalChatPosition = (position) => ({
    type: ACTIVE_INTERNAL_CHAT_POSITION,
    payload: position
});

export const setTextMessageInternal = (textMessageInternal) => ({
    type: TEXT_MESSAGE_INTERNAL,
    payload: textMessageInternal
});
export const setRoleDescription = (description) => ({
    type: ROLE_DESCRIPTION,
    payload: description
});

export const setFullDialogInternal = (fullDialogInternal) => ({
    type: FULL_DIALOG_INTERNAL,
    payload: fullDialogInternal
});

export const setInternalMuted = (internalMuted) => ({
    type: INTERNAL_MUTED,
    payload: internalMuted
});
