export const ALERT_ERROR = "danger"
export const ALERT_SUCCESS = "success"
export const MASK_CNPJ = "99.999.999/9999-99"
export const OPERATOR = "OPERATOR"
export const PATTERN_PHONE = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const PHONE = /^\(?([0-9]{2})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/
export const PHONE_NUMBER_MASK = ["(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
export const RESELLER = "RESELLER"
export const ROLE_ADMIN = "ROLE_ADMIN"
export const ROLE_OPERATOR = "ROLE_OPERATOR"
export const ROLE_OPERATOR_FULL = "ROLE_OPERATOR_FULL"
export const ROLE_RESELLER = "ROLE_RESELLER"
export const ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN"
export const SCREEN_BLOCKED = "BLOCKED"
export const SCREEN_NON_SUBSCRIBER = "NON_SUBSCRIBER"
export const SCREEN_PAYMENT_FAILED = "PAYMENT_FAILED"
export const SCREEN_REGISTRATION = "registrations"
export const SCREEN_SUBSCRIBER = "SUBSCRIBER"
export const SCREEN_TEST= "TEST"