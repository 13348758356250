import {
    CHAT_USER, ACTIVE_CHAT_POSITION, DEPARTMENT_FILTER, FULL_DIALOG, ADD_LOGGED_USER, CREATE_GROUP, SENDER_NAME, TEXT_MESSAGE, MUTED,
    ONLINE_USERS
} from './constants';


export const chatUser = () => ({
    type: CHAT_USER
});

export const activeChatPosition = (position) => ({
    type: ACTIVE_CHAT_POSITION,
    payload : position
});

export const setDepartmentFilter = (selectedOptions) => ({
    type: DEPARTMENT_FILTER,
    payload: selectedOptions
});

export const setTextMessage = (textMessage) => ({
    type: TEXT_MESSAGE,
    payload: textMessage
});
export const setSenderName = (name) => ({
    type: SENDER_NAME,
    payload: name
});

export const setFullDialog = (fullDialog) => ({
    type: FULL_DIALOG,
    payload : fullDialog
});

export const setOnlineUsers = (onlineUsers) => ({
    type: ONLINE_USERS,
    payload : onlineUsers
});

export const addLoggedinUser = (userData) => ({
    type: ADD_LOGGED_USER,
    payload : userData
});

export const setMuted = (muted) => ({
    type: MUTED,
    payload: muted
});

export const createGroup = (groupData) => ({
    type : CREATE_GROUP,
    payload : groupData
})
