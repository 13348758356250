import jwtDecode from 'jwt-decode';
import {
    ROLE_SUPER_ADMIN,
    ROLE_ADMIN,
    ROLE_RESELLER
} from '../constants/constants';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }
    
    try {
        const decoded = jwtDecode(user.token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        }
        else {
            return true;
        }
    } catch(e) {
        console.warn('access token expired');
        return false;
    }
}

/**
 * Sets the logged in user
 */
const setLoggedInUser = (user) => {
    localStorage.setItem('authUser', JSON.stringify(user));
}

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const user = localStorage.getItem('authUser');
    return user ? (typeof (user) == 'object' ? user : JSON.parse(user)) : null;
}

const getHomeByLoggedInUser = () => {
    const user = localStorage.getItem('authUser');
    let userLogged = user ? (typeof (user) == 'object' ? user : JSON.parse(user)) : null;

    switch (userLogged.data.roles[0].name.toString()) {
        case ROLE_SUPER_ADMIN:
            return "/wzap-administrator";
        case ROLE_ADMIN:
            return "/panel";
        case ROLE_RESELLER:
            return "/dashboard";
        default:
            return "/dashboard";
    }
}

const getInstanceId = () => {
    const instanceId = localStorage.getItem('instanceId');
    return instanceId ? (typeof (instanceId) == 'object' ? instanceId : JSON.parse(instanceId)) : null;
}

export { isUserAuthenticated, setLoggedInUser, getLoggedInUser, getHomeByLoggedInUser, getInstanceId };