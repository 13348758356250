import storage from 'redux-persist/es/storage';
import { persistReducer } from 'redux-persist';

// eslint-disable-next-line import/no-anonymous-default-export
export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: 'wzap',
      storage,
      whitelist: ['Adm', 'auth', 'user', 'Chat', 'ChatInternal', 'Layout' ],
    },
    reducers
  );

  return persistedReducer;
};
