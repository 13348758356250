import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import createStore from './createStore';
import createHistory from 'history/createBrowserHistory';

import rootReduce from './reducers';
import rootSaga from './sagas';
import persistReducers from './persistReducers';

//const sagaMonitor = process.env.NODE_ENV === 'development' ? console.tron.createSagaMonitor() : null;
//const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
const history = createHistory();
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const store = createStore(persistReducers(
    rootReduce), middlewares);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);


export { history, store, persistor };
