import { TEXT_MESSAGE_INTERNAL, INTERNAL_MUTED, ACTIVE_INTERNAL_CHAT_POSITION, ROLE_DESCRIPTION, FULL_DIALOG_INTERNAL } from './constants';

const INIT_STATE = {
    active_internal_chat_position: 0,
    internalMuted: false,
    textMessageInternal: "",
    roleDescription: "",
    dialogsInternal: [],
};

const ChatInternal = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTIVE_INTERNAL_CHAT_POSITION:
            return {
                ...state,
                active_internal_chat_position: action.payload
            };
        case TEXT_MESSAGE_INTERNAL:
            return {
                ...state,
                textMessageInternal: action.payload
            };
        case ROLE_DESCRIPTION:
            return {
                ...state,
                roleDescription: action.payload
            };
        case INTERNAL_MUTED:
            return {
                ...state,
                internalMuted: action.payload
            }
        case FULL_DIALOG_INTERNAL:
            return {
                ...state,
                dialogsInternal: action.payload
            };
        default: return { ...state };
    }
}

export default ChatInternal;